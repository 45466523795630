import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import SearchBox from './SearchBox';
import { Interactable, SearchVariant } from './SearchForm';

interface Props {
    variant?: SearchVariant;
    className?: string;
    active?: Interactable[];
    hideSearchIcon?: boolean;
    homeBarLabel?: React.ReactElement<typeof FormattedMessage>;
}

const SearchBar: FC<React.PropsWithChildren<Props>> = ({
    variant = 'searchpage',
    className,
    active,
    hideSearchIcon,
    homeBarLabel,
}) => (
    <Container variant={variant} className={`${className ?? ''} searchbar`}>
        <SearchBox
            hideSearchIcon={hideSearchIcon}
            hideCalendarIcon
            active={active}
            variant={variant}
            homeBarLabel={homeBarLabel}
        />
    </Container>
);

const Container = styled.div<{ variant: SearchVariant }>`
    display: flex;
    align-items: center;
    border-radius: calc(${({ theme }) => theme.radius.button} + ${({ theme }) => theme.radius.button}*0.5);

    position: relative;
    z-index: 3;

    ${({ variant }) =>
        (variant === 'searchpage' || variant === 'homeBar') &&
        css`
            padding-top: 2.4rem;

            @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
                padding-top: 4rem;
            }
        `}

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding-top: 0;
        gap: 2rem;

        ${({ variant }) =>
            variant === 'hero'
                ? css`
                      padding: 0;
                  `
                : css`
                      padding: 4rem 0;
                  `}
    }

    ${({ variant }) =>
        variant === 'searchMap' &&
        css`
            @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.m - 1}px) {
                position: absolute;
                top: 4rem;
                left: var(--wrapperPadding);
                margin: 0;
            }

            @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.s - 1}px) {
                top: 2.4rem;
                right: var(--wrapperPadding);
            }

            @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
                margin-top: 4rem;
            }
        `}
`;
export default SearchBar;
